




























import { computed, defineComponent, toRefs, watch } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { getNews, getNewsCount } from './api';
import VClamp from 'vue-clamp';
import NewsCard from '@/components/NewsCard.vue';

export default defineComponent({
  components: { VClamp, NewsCard },
  setup(_, { root }) {
    const main = useMainStore();

    const { result: news, refresh } = useApi((page?: number, size?: number) =>
      getNews({ page: page || 1, size: size || 10 }),
    );

    const { result: total } = useApi(() => getNewsCount());
    return {
      news: computed(
        () =>
          news.value?.slice(0, 8).map(n => ({ ...n, thumbnail: main.state.oss + n.thumbnail })) ||
          [],
      ),
      total,
      onPageChange: (page: number) => {
        refresh(page, 10);
      },
      toDetail: (id: number) => {
        root.$router.push({ name: 'NewsDetail', params: { id: id.toString() } });
      },
    };
  },
});
